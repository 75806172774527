import { GatsbyImage, getImage } from "gatsby-plugin-image"

import React from "react"

const NewsHero = ({img, alt}) => (
<div className="relative max-w-1920 mx-auto">
    <div className="w-full">
        <GatsbyImage image={getImage(img)} alt={alt} className="h-full block" />
    </div>
</div>
)

export default NewsHero
