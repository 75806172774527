import { Footnotes, FootnotesProvider, FootnoteRef as Ref } from "../footnotes"

import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import FootnotesBackLink from "../components/FootnotesBackLink"
import LastUpdated from "../components/LastUpdated"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import NewsHero from "../components/NewsHero"
import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const shortcodes = { Link, Ref }

export const pageQuery = graphql`
query NewsPage($slug: String) {
  mdx(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      slug
      title
      desc
      categories      
      hero {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, quality: 90)
        }
      }
      hero_alt
      date
      embeddedLocalImages {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, quality: 90)
        }
      }
    }
    body
  }
}
`


const NewsPageLayout = (props) => {
  console.warn('props', props)
  console.warn('props.pageContext', props.pageContext)
  console.warn('props.data', props.data)

    const frontmatter = props.data.mdx.frontmatter;
    const { breadcrumb: { crumbs } } = props.pageContext;

    

   const published_date = new Date(frontmatter.date).toLocaleDateString('en-US', { timeZone: 'UTC', weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'})
    
    return <Layout >
        <FootnotesProvider>
          <Seo title={frontmatter.title} description={frontmatter.desc} />
          <div className="news-page bg-dark-night">

              <NewsHero img={frontmatter.hero} alt={frontmatter.hero_alt} />

              <div className="max-w-1920 mx-auto min-h-50vh px-5 sm:px-12">
                  <Breadcrumb crumbs={crumbs} crumbLabel={frontmatter.title} crumbSeparator="»" />

                  <div className="flex flex-col md:flex-row max-w-6xl mx-auto">
                    <div className="news-body px-5 mb-5 w-full  md:w-3/4">
                      <h2 className="font-bold text-4xl mb-5">{frontmatter.title}</h2>
                      <MDXProvider components={shortcodes}>
                          <MDXRenderer localImages={frontmatter.embeddedLocalImages}>{props.data.mdx.body}</MDXRenderer>
                      </MDXProvider>
                    </div>
                    <div className="px-5 w-full md:w-1/4">
                      <dl>
                        <dt>Published:</dt> <dd>{published_date}</dd>
                      </dl>
                    </div>
                  </div>
                  
                  <Footnotes BackLink={FootnotesBackLink} />
                  {frontmatter.last_modified && <LastUpdated date={frontmatter.last_modified} />}
              </div>
          </div>
        </FootnotesProvider>
    </Layout>
};


export default NewsPageLayout